import useWindowResize from "../windowDimensionHelper";
import { mdiEmailOutline, mdiEmailEditOutline } from "@mdi/js"
import PropTypes from "prop-types";
import LinkWithIcon, { PhoneContact } from "../components/LinkWithIcon";

function Map(props) {
	const { location, ...otherProps } = props;
	// TODO: show loading while iframe is loading
	return (
		<iframe
			title={"map"}
			src={`https://www.google.com/maps/embed?${location}`}
			height="450"
			width={"100%"}
			allowFullScreen="" loading="lazy"
			referrerPolicy="no-referrer-when-downgrade"
			{...otherProps}
		/>
	);
}

const googleMapLocations = {
	beroun: "pb=!1m18!1m12!1m3!1d2565.946133500946!2d14.075725315940355!3d49.97486872939865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470ba547a1594d4b%3A0x5f4bda18c7adb44e!2zTcOhY2hvdmEgODc2LCBaw6F2b2TDrSwgMjY2IDAxIEJlcm91bg!5e0!3m2!1sen!2scz!4v1665585167390!5m2!1sen!2scz",
	plzen: "pb=!1m18!1m12!1m3!1d1290.0608345563533!2d13.416096855320372!3d49.70851110209672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470aefb9eb562c23%3A0x4f62a7aacbd4aad5!2sV%20Kamen%C3%AD%2021%2C%20326%2000%20Plze%C5%88%202-Slovany%2C%20Czechia!5e0!3m2!1sen!2sus!4v1667158141283!5m2!1sen!2sus"
}

function ContactsInfoBeroun({ horizontal }) {
	return (
		<div>
			<h2 style={{
				...(horizontal ? {
					textAlign: "center",
				} : {})
			}}>KONTAKT</h2>
			<div style={{
				...(horizontal ? {
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					columnGap: "2rem",
					width: "100%",
				} : {})
			}}>
				<div>
					<h3>Ing.&nbsp;arch. Šárka&nbsp;Seidlová</h3>
					<p>
						Máchova 876
						<br/>
						Beroun
						<br/>
						266 01
					</p>
				</div>
				<div>
					<PhoneContact/>
					<LinkWithIcon icon={mdiEmailOutline} iconHover={mdiEmailEditOutline} link={"mailto:sarka.seidlova@email.cz"}>sarka.seidlova@email.cz</LinkWithIcon>
					<p>IČO 663 49419</p>
					<p>Bankovní spojení: 249912 777/0300</p>
				</div>
			</div>
		</div>
	);
}

ContactsInfoBeroun.propTypes = {
	horizontal: PropTypes.bool,
}
ContactsInfoBeroun.defaultProps = {
	horizontal: false,
}

function ContactsInfoPlzen({ horizontal }) {
	return (
		<div>
			<p style={{
				...(horizontal ? {
					textAlign: "center",
				} : {})
			}}>
				Pobočka Plzeň:
			</p>
			<div style={{
				...(horizontal ? {
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
				} : {})
			}}>
				<p>
				V Kamení 21
				<br/>
				Plzeň
				<br/>
				326 00
			</p>
			</div>
		</div>
	);
}

ContactsInfoPlzen.propTypes = {
	horizontal: PropTypes.bool,
}
ContactsInfoPlzen.defaultProps = {
	horizontal: false,
}


function Contact() {
	const displaySize = useWindowResize();

	switch (displaySize) {
		default:
		case "l":
			return (
				<div style={{
					display: "flex",
					flexDirection: "row",
					columnGap: "2rem",
				}}>
					<div style={{
						flex: "0 1 fit-content",
					}}>
						{<ContactsInfoBeroun/>}
					</div>
					<div style={{
						flex: "2 1 0",
					}}>
						<Map location={googleMapLocations.beroun}/>
					</div>
					<div style={{
						marginLeft: "4rem",
						flex: "0 1 fit-content",
					}}>
						{<ContactsInfoPlzen/>}
					</div>
					<div style={{
						flex: "2 1 0",
					}}>
						<Map displaySize={displaySize} location={googleMapLocations.plzen}/>
					</div>
				</div>
			);
		case "m":
			return (
				<>
					<table style={{
						width: "100%",
						borderCollapse: "collapse",
					}}>
						<colgroup>
							<col style={{ width: "fit-content" }}/>
						</colgroup>
						<tr>
							<td style={{
								verticalAlign: "top",
							}}>
								{<ContactsInfoBeroun/>}
							</td>
							<td style={{
								paddingBottom: "2rem",
							}}>
								<Map location={googleMapLocations.beroun}/>
							</td>
						</tr>
						<tr>
							<td style={{
								verticalAlign: "top",
							}}>
								{<ContactsInfoPlzen/>}
							</td>
							<td>
								<Map location={googleMapLocations.plzen}/>
							</td>
						</tr>
					</table>
				</>
			);
		case "s":
			return (
				<>
					{<ContactsInfoBeroun horizontal/>}
					<Map location={googleMapLocations.beroun}/>
					{<ContactsInfoPlzen horizontal/>}
					<Map location={googleMapLocations.plzen}/>
				</>
			);
	}
}

export default Contact;