import { Link, NavLink } from "react-router-dom";
import "../WebsiteHeader.css";
import { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import useWindowResize from "../windowDimensionHelper";
import { HEADER_HEIGHT } from "../constants";
import { createUseStyles } from "react-jss";

const CONTENT_WIDTH = 1400;

const useStyles = createUseStyles({
	headerSpacer: {
		height: HEADER_HEIGHT,
	},
	headerWrapper: {
		height: HEADER_HEIGHT,
		width: "100%",
		top: 0,
		left: 0,
		position: "fixed",
		backgroundColor: "#0b0b0e",
		zIndex: 1000,
	},
	homeLink: ({ displaySize }) => ({
		fontSize: displaySize === "s" ? "2em" : "3em",
		display: "inline-block",
		padding: "0.2em 0",
		color: "white",
		textDecoration: "none",
	}),
	navBar: ({
		display: "flex",
		flexDirection: "row",
		columnGap: "0.8em",
	}),
	menuDropdownLabel: {
		display: "inline-block",
		padding: "0.2em 0.8em",
		color: "white",
		fontSize: "1.5em",
		cursor: "pointer",
		userSelect: "none",
		border: ({ isOpen }) => isOpen ? "5px gray" : "0",
		"&:hover": {
			backgroundColor: "#202020"
		}
	},
})

const headerStyles = createUseStyles({

	navLink: {
		display: "inline-block",
		padding: "0.2em 0",
		paddingRight: 0,
		color: "white",
		textDecoration: props => props.isActive ? "underline" : "none",
		fontSize: "1.5em",
		"&:hover": {
			color: "#d2d2d2"
		}
	},
})

const Header = styled.header(({ displayVertical }) => ({
	height: "100%",
	maxWidth: `${CONTENT_WIDTH - 35 * 2}px`,
	margin: "0 auto",
	display: "flex",
	flexDirection: displayVertical ? "column" : "row",
	justifyContent: "space-between",
	alignItems: displayVertical ? "center" : "baseline",
	padding: "0 35px",
	paddingTop: displayVertical ? "0" : "15px",
}))


function NavLinks({ styles, isOpen, setIsOpen, ...props }) {

	const linkElements = props.links.map(({ label, to }, index) =>
		<NavLink
			className={({ isActive }) => headerStyles({ isActive }).navLink}
			to={to}
			key={index}
			onClick={() => setIsOpen(false)}
			end>
			{label}
		</NavLink>);

	if (props.dropdownMode) {
		return (
			<nav>
				<div>
					<div className={styles.menuDropdownLabel} onClick={() => setIsOpen(!isOpen)}>
						Menu
					</div>
					{isOpen && <div style={{
						position: "absolute",
						backgroundColor: "gray",
						bottom: 0,
						left: "50%",
						transform: "translate(-50%, 100%)",
						display: "flex",
						flexDirection: "column",
					}}>
						{linkElements}
					</div>}
				</div>
			</nav>
		);
	}
	return (
		<nav className={styles.navBar}>
			{linkElements}
		</nav>
	);
}

NavLinks.propTypes = {
	links: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string.isRequired,
		to: PropTypes.string.isRequired,
	})),
	dropdownMode: PropTypes.bool,
}

function WebsiteHeader() {

	const [isOpen, setOpen] = useState(false);
	const [displaySize, setDisplaySize] = useState("l");
	const styles = useStyles({ displaySize, isOpen });

	useWindowResize(sizeStr => setDisplaySize(sizeStr));

	return (
		<div>
			<div className={styles.headerSpacer}/>
			<div className={styles.headerWrapper}>
				<Header displayVertical={displaySize === "s"}>
					<div>
						<Link className={styles.homeLink} to={"/"} onClick={() => setOpen(false)}>
							ŠÁRKA SEIDLOVÁ
						</Link>
					</div>
					<NavLinks
						styles={styles}
						isOpen={isOpen}
						setIsOpen={setOpen}
						links={[
							{
								label: "PROJEKTY",
								to: "/projects",
							},
							{
								label: "ATELIÉR",
								to: "/atelier",
							},
							{
								label: "KONTAKT",
								to: "/contact",
							}
						]} dropdownMode={displaySize === "s"}/>
				</Header>
			</div>
		</div>
	);
}


export default WebsiteHeader;