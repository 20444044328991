import Icon from "@mdi/react";
import { mdiPhone, mdiPhoneHangup } from "@mdi/js";
import { useState } from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
	main: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		columnGap: "0.5rem",
		textDecoration: "none",
		color: "inherit",
		"&:hover": {
			color: "#daf8ff",
		}
	}
});

function LinkWithIcon({ link, icon, iconHover = null, ...props }) {
	iconHover ??= icon;
	const styles = useStyles();
	const [isHovered, setIsHovered] = useState(false);
	return <a className={styles.main}
						onMouseEnter={_ => {
							setIsHovered(true);
						}}
						onMouseLeave={_ => {
							setIsHovered(false);
						}}
						href={link}
	>
		<Icon path={isHovered ? iconHover : icon} size={1}/>
		<div>{props.children}</div>
	</a>;
}

function PhoneContact() {
	const phone = "+420 606 907 003";
	return <LinkWithIcon link={`tel:${phone}`} icon={mdiPhoneHangup} iconHover={mdiPhone}>{phone}</LinkWithIcon>;
}

export default LinkWithIcon;
export { PhoneContact };