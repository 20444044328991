import useWindowResize from "../windowDimensionHelper";
import { createUseStyles } from "react-jss";

const portrait = require("../assets/portrait.jpg");

const useStyles = createUseStyles({
	main: ({isMobile}) => ({
		"& *": {fontWeight: "normal"},
		...(isMobile ? {
		} : {
			width: "100%",
			display: "flex",
			flexDirection: "row",
			justifyContent: "flex-start",
		})
	}),
	servicesContainer: () => (
		{
			fontSize: "1.2rem",
			"& ul": {
				paddingLeft: "1rem",
			}
		}),
	servicesListsContainer: ({ isMobile }) => (
		{
			...(isMobile ? {} :
				{
					width: "100%",
					margin: "0 auto",
					display: "flex",
					flexDirection: "row",
					flexWrap: "wrap",
					columnGap: "2rem",
					"& > div": {
						flex: "1 1 0",
						minWidth: "15rem"
					},
				})
		})
})

const TIMELINE_DATA = [
	{
		startYear: "2008",
		description: "Vlastní architektonický ateliér",
	},
	{
		startYear: "1998",
		endYear: "2007",
		description: "Praxe v architektonických ateliérech",
	},
	{
		startYear: "1993",
		endYear: "1993",
		description: `Stáž na Faculté d'architecture de Nantes`,
	},
	{
		startYear: "1991",
		endYear: "1998",
		description: "Fakulta architektury ČVUT v Praze",
	}
];

function Timeline() {
	return <table style={{
		width: "max-content"
	}}>
		{TIMELINE_DATA.map((item, index) =>
			<tr key={index}>
				<td>
					{item.startYear}
				</td>
				<	td>
					{item.startYear === item.endYear ? "" : "-"}
				</td>
				<td>
					{item.startYear !== item.endYear ? item.endYear : ""}
				</td>
				<td>
					{item.description}
				</td>
			</tr>
		)}
	</table>
}
function Services(props) {

	const { isMobile, styles, ...otherProps } = props;

	const { servicesContainer, servicesListsContainer } = styles;

	return <div className={servicesContainer} {...otherProps}>
		<h2 style={{ marginTop: 0, marginBottom: "0.3rem" }}>
			NABÍDKA SLUŽEB
		</h2>
		<div className={servicesListsContainer}>
			<div>
				<h4>
					Architektonické studie
				</h4>
				<ul>
					<li>Rodinné domy</li>
					<li>Rekonstrukce</li>
					<li>Přístavby</li>
					<li>Podkrovní vestavby</li>
					<li>Drobné stavby, altány, pergoly</li>
					<li>Okolí domu</li>
					<li>Dispoziční úpravy</li>
					<li>Úpravy fasád a střech</li>
				</ul>
			</div>
			<div>
				<h4>
					Návrhy interiérů
				</h4>

				<ul>
					<li>Rodinné domy</li>
					<li>Byty</li>
					<li>Kanceláře</li>
					<li>Recepce</li>
					<li>Reprezentativní prostory</li>
					<li>Hotelové pokoje a apartmány</li>
					<li>Atypický nábytek</li>
					<li>Kuchyně</li>
					<li>Koupelny</li>
				</ul>
			</div>
			<div>
				<h4>
					Konzultace
				</h4>

				<ul>
					<li>První konzultace zdarma</li>
					<li>Drobné úpravy dispozic</li>
					<li>Změny rozmístění nábytku</li>
					<li>Drobné úpravy okolí domu</li>
				</ul>

			</div>
		</div>
		<div>
			<i>
				<ul style={{
					listStyleType: "circle"
				}}>
					<li>Studii předávám projekční kanceláři ke zpracování projektu pro stavební povolení</li>
					<li>Asistuji při výběru dodavatele</li>
					<li>Asistuji při výběru materiálů, nábytku a dekorací</li>
					<li>Osobně dohlížím na realizaci díla pro maximální spokojenost klienta</li>
				</ul>
			</i>
		</div>
	</div>;
}

function Title() {
	return (
		<h3 style={{
			paddingLeft: "0.3rem"
		}}>
			<span style={{ fontSize: "0.8rem" }}>
				Ing. arch.
			</span>{" "}
			Šárka Seidlová
		</h3>);
}

function Portrait() {
	return <img src={portrait} alt={"Portrét"} style={{
		height: 300,
		width: "auto",
		border: "2px solid gray",
		objectFit: "contain",
		boxSizing: "border-box"
	}}/>;
}

function Atelier() {


	const windowSizeStr = useWindowResize();

	let isMobile = windowSizeStr === "s";

	const styles = useStyles({ isMobile });

	if (isMobile) {
		return <div className={styles.main}>
			<div style={{
				width: "fit-content",
				margin: "0 auto",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}>
				<Portrait/>
				<div>
					<Title/>
					<Timeline/>
				</div>
			</div>
			<Services styles={styles} isMobile style={{}}/>
		</div>;
	}

	return (
		<div className={styles.main}>
			<div>
				<div style={{
					display: "flex",
					flexWrap: "wrap",
					flexDirection: "column",
					justifyContent: "flex-start",
					columnGap: "20px"
				}}>
					<Portrait/>
					<div style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
					}}>
						<Title/>
						<Timeline/>
					</div>
				</div>
			</div>
			<div style={{
				marginLeft: "3em",
			}}>
				<Services styles={styles}/>
			</div>
		</div>
	);
}

export default Atelier;