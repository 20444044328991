import { useEffect, useState } from "react";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height
	};
}

function toSizeStr(dimensions) {
	switch (true) {
		case (dimensions.width < 800):
			return "s";
			break;
		case (dimensions.width < 1200):
			return "m";
		default:
			return "l";
	}
}

/**
 *
 * @param {function} onResize
 */
function useWindowResize(onResize = () => {
}) {
	const [windowSizeStr, setWindowSizeStr] = useState(toSizeStr(getWindowDimensions()));

	useEffect(() => {
		function handleResize() {
			let dimensions = getWindowDimensions();
			let sizeStr = toSizeStr(dimensions);
			setWindowSizeStr(sizeStr);
			if (onResize) {
				onResize(sizeStr, dimensions);
			}
		}

		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, [onResize]);
	return windowSizeStr;
}

export default useWindowResize;