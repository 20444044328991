import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import { importAll } from "../helpers";
import useWindowResize from "../windowDimensionHelper";
import { useState } from "react";
import { createUseStyles } from "react-jss";

let previews = importAll(require.context("../assets/projects", true, /preview.(?:jpg|JPG|png|PNG)$/));
let projectData = importAll(require.context("../assets/projects", true, /data.json$/));

let projects = Object.keys(previews).map(
	(key) => {
		let path = key.split("/")[0];
		const hasPreference = path.match(/^_(\d+?)_/);
		return (
			{
				path,
				label: path.replace(/^_\d+_/, ""),
				preference: hasPreference ? parseInt(hasPreference[1]) : undefined,
				preview: previews[key],
				projectData: projectData[path + "/data.json"],
			}
		);
	}
);

projects.sort((a, b) => {
	if (a.preference !== undefined && b.preference !== undefined) {
		return b.preference - a.preference;
	}
	if (a.preference !== undefined) {
		return 1;
	}
	if (b.preference !== undefined) {
		return -1;
	}

	return a.label.localeCompare(b.label);
});

const IMAGE_HOVER_BORDER_SIZE = 3;

const useStyles = createUseStyles({
	imageLabelStyle: {
		position: "absolute",
		bottom: "0",
		left: "0",
		transform: `translateY(-${IMAGE_HOVER_BORDER_SIZE}px)`,
		width: "100%",
		height: "3rem",
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		color: "white",
		textAlign: "center",
		fontSize: "1rem",
		lineHeight: "1rem",

		boxSizing: "border-box",
		border: "0 solid gray",
		transition: "border 0.1s ease-out",
	},
	previewContainerLinkStyle: {
		width: "100%", height: "100%",
		textDecoration: "none",
		position: "relative",

		[`&:hover img`]: {
			filter: "grayscale(0%)",
			border: `${IMAGE_HOVER_BORDER_SIZE}px solid gray`
		},
		[`&:hover #label`]: {
			filter: "grayscale(0%)",
			border: `${IMAGE_HOVER_BORDER_SIZE}px solid gray`,
			borderTop: "0 solid gray",
		}
	},
	previewImageStyle: ({ isMobile }) => ({
		width: "auto",
		boxSizing: "border-box",
		filter: `${!isMobile ? "grayscale(100%)" : "grayscale(0%)"}`,
		border: "0 solid gray",
		transition: "filter 0.1s ease-out, border 0.1s ease-out",
		objectFit: "cover",
	})
})

function PreviewContainer(props) {
	const {
		previewContainerLinkStyle,
		previewImageStyle,
		imageLabelStyle
	} = useStyles({ isMobile: props.isMobile });
	return <div style={{
		flex: "1 1 0",
		aspectRatio: "4/3"
	}}>
		{props.to && <Link className={previewContainerLinkStyle} to={props.to}>
			<img
				src={props.src}
				className={previewImageStyle}
				style={{ width: "100%", height: "100%" }}/>
			<div
				className={imageLabelStyle}
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
				}}
				id="label"
			>
				<div style={{}}>
					{props.label}
				</div>
			</div>
		</Link>}
	</div>;
}

PreviewContainer.propTypes = {
	src: PropTypes.string,
	label: PropTypes.string,
	to: PropTypes.string
}

function Projects() {
	const [columnCount, setColumnCount] = useState(1);

	function recalculateColumns(sizeStr) {
		if (sizeStr === "s") {
			setColumnCount(1);
		} else {
			setColumnCount(3);
		}
	}

	useWindowResize(recalculateColumns);

	const previews = projects.map(({ path, preview, projectData, label }, index) =>
		<PreviewContainer key={index} src={preview}
											label={projectData?.label ?? ""} to={path}
											$columnCount={columnCount}
											isMobile={isMobile}/>).reverse();
	const groupedPreviews = [];
	for (let i = 0; i < previews.length; i += columnCount) {
		let items = previews.slice(i, i + columnCount);
		while (items.length < columnCount) {
			items.push(<PreviewContainer key={`Empty${items.length}`} isMobile={isMobile}/>);
		}
		groupedPreviews.push(items);
	}
	return (
		<div style={{
			display: "flex",
			flexDirection: "column",
			flexWrap: "nowrap",
			gap: "1rem",
			justifyContent: "center",
			alignContent: "flex-start",
			width: "100%",
			height: "100%"
		}}>
			{groupedPreviews.map((row, index) => <div key={index} style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				gap: "1em"
			}}>{row}</div>)}
		</div>
	);
}


export default Projects;