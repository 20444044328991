import './App.css';
import WebsiteHeader from "./components/WebsiteHeader";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import ProjectView from "./pages/ProjectView";
import Atelier from "./pages/Atelier";
import styled from "styled-components";
import Footer from "./components/Footer";

let contentWidth = 1400;

const ContentDiv = styled.div`
	padding: 20px 35px;
  height: 100%;

  & > div {
    height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
  }
`;

const AppWrapper = styled.div`
  background-color: #0b0b0e;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  font-family: 'Arial Nova', "Arial", sans-serif;
  font-stretch: condensed;
  font-weight: lighter;
  overflow-x: hidden;
`

const AppCenterColumn = styled.div`
  width: ${contentWidth}px;
	max-width: 100%;
  margin: 0 auto;
  height: 100%;
	overflow-y: hidden;
`

function App() {
	return (
		<AppWrapper>
			<AppCenterColumn>
				<BrowserRouter>
					<WebsiteHeader/>
					<ContentDiv>
						<div>
							<Routes>
								<Route index element={<Home/>}/>
								<Route path={"contact"} element={<Contact/>}/>
								<Route exact={false} path={"projects"} element={<Projects/>}/>
								<Route exact={false} path={"projects/:name"} element={<ProjectView/>}/>
								<Route path={"atelier"} element={<Atelier/>}/>
							</Routes>
							<Footer/>
						</div>
					</ContentDiv>
				</BrowserRouter>
			</AppCenterColumn>
		</AppWrapper>
	);
}

export default App;
