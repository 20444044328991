import { Link, useParams, useSearchParams } from "react-router-dom";
import { importAll } from "../helpers";
import styled from "styled-components";
import { createUseStyles } from "react-jss";
import { useEffect } from "react";
import { HEADER_HEIGHT } from "../constants";
import { mdiMenuLeft, mdiMenuRight } from "@mdi/js";
import Icon from "@mdi/react";
import useWindowResize from "../windowDimensionHelper";
import PropTypes from "prop-types";
import { ProgressiveImage } from "../components/ProgressiveImage";

let projectData = importAll(require.context("../assets/projects", true, /data.json$/));
let allImages = importAll(require.context("../assets/projects", true, /\.(?:jpg|JPG|png|PNG)$/));

const useStyles = createUseStyles({
	imgStyle: ({ isMobile }) => {

		if (isMobile) {
			return {
				objectFit: "contain",
				width: "100%",
				boxSizing: "border-box",
			}
		}

		return  {
			width: "250px",
			height: "250px",
			objectFit: "cover",
			boxSizing: "border-box",
			border: 0,
			transition: "filter 0.1s ease-out, border 0.1s ease-out",
			"&:hover": {
				border: "3px solid gray"
			},
			userSelect: "none",
		};
	},
});

let projects = {};
for (let project in projectData) {
	let projectPathSplit = project.split("/");
	projectPathSplit.pop();
	let projectPath = projectPathSplit.join("/");
	projects[projectPath] = { data: projectData[project], images: {} };
}

for (let image in allImages) {
	let imagePathSplit = image.split("/");
	let fileName = imagePathSplit.pop();
	let projectPath = imagePathSplit.join("/");

	if (fileName.startsWith("preview")) {
		continue;
	}
	let imageName = fileName;
	let addition = {};
	if (fileName.startsWith("thumbnail_")) {
		imageName = fileName.replace("thumbnail_", "");
		addition = { thumbnail: allImages[image] };
	} else {
		addition = { full: allImages[image] };
	}
	projects[projectPath].images[imageName] = { ...projects[projectPath].images[imageName], ...addition };
}

for (let project in projects) {
	projects[project].images = Object.values(projects[project].images);
}

const ProjectHeader = styled.h1`
  font-size: 2rem;
  text-align: center;
	font-weight: normal;
`

const ImagesContainer = styled.div(({ isMobile }) => ({
	display: "flex",
	flexDirection: isMobile ? "column" : "row",
	gap: "1rem",
	flexWrap: "wrap",
	justifyContent: "flex-start",
}))

function GalleryNavigationArrow({ direction, onClick, galleryImageId, galleryLength }) {

	const show = direction === "left" ?
		galleryImageId > 0 :
		galleryImageId < galleryLength - 1;

	let maxArrowWidth = "3vw";

	return (
		<div style={{
			height: "100%",
			width: "20rem",
			maxWidth: "5vw",
			display: "flex",
			alignContent: "center",
			cursor: show ? "pointer" : "default",
		}}
				 onClick={onClick}
		>
			<Icon path={direction === "left" ? mdiMenuLeft : mdiMenuRight}
						style={{
							maxWidth: maxArrowWidth,
							opacity: show ? 0.5 : 0,
							marginLeft: direction === "left" ? 0 : "auto",
							marginRight: direction === "left" ? "auto" : 0,
						}}/>
		</div>)
}

function FullscreenGallery({ projectName, id, show = false, setSearchParams }) {
	let transitionTime = 0.3;

	id = parseInt(id);

	let images = projects[projectName].images;

	return (
		<div style={{
			position: "fixed",
			top: HEADER_HEIGHT,
			left: 0,
			width: "100%",
			height: `calc(100vh - ${HEADER_HEIGHT}px)`,
			backgroundColor: "#0b0b0e",
			opacity: show ? 1 : 0,
			transform: show ? "scale(1)" : "scale(0)",
			transition: `opacity ${transitionTime}s ease-out, transform ${transitionTime}s ease-out`,
			display: "flex",
		}}
		>
			<GalleryNavigationArrow
				direction={"left"}
				galleryImageId={id}
				galleryLength={images.length}
				onClick={() => {
					setSearchParams({ gallery: Math.max(id - 1, 0), show: true });
				}}
			/>
			<div style={{
				height: "100%",
				flexGrow: 1,
			}}
					 onClick={() => {
						 setSearchParams({ gallery: id });
					 }}
			>

				<div style={{
					padding: "2rem",
					width: "100%",
					height: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					boxSizing: "border-box",
				}}>
					<ProgressiveImage
						thumbnail={images[id].thumbnail}
						src={images[id].full}
						style={{
							maxHeight: "100%",
							maxWidth: "100%",
							objectFit: "contain",
							userSelect: "none",
						}}/>
				</div>
			</div>
			<GalleryNavigationArrow
				direction={"right"}
				galleryImageId={id}
				galleryLength={images.length}
				onClick={() => {
					setSearchParams({ gallery: Math.min(id + 1, images.length - 1), show: true });
				}}
			/>
		</div>
	);
}

ProgressiveImage.propTypes = {
	src: PropTypes.string.isRequired,
	thumbnail: PropTypes.string.isRequired,
	alt: PropTypes.string,
}

ProgressiveImage.defaultProps = {
	alt: "",
}

function GalleryImage({ src, index, isMobile }) {

	const { imgStyle } = useStyles({ isMobile });

	let image = <img src={src} className={imgStyle}/>;

	if (isMobile) {
		return image;
	}

	return (<Link to={`?gallery=${index}&show=true`}>
		{image}
	</Link>);
}

function ProjectView() {
	const { name } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	const windowSizeStr = useWindowResize();

	let galleryImageId = searchParams.get("gallery") ?? 0;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])

	let show = searchParams.get("show") === "true";
	let isMobile = windowSizeStr === "s";
	return <div>
		<FullscreenGallery projectName={name} id={galleryImageId} show={show} setSearchParams={setSearchParams}/>
		<ProjectHeader>{projects[name].data.label}</ProjectHeader>
		<ImagesContainer isMobile={isMobile}>
			{projects[name].images.map(({ full, thumbnail }, index) =>
				<GalleryImage key={index}
											src={isMobile ? full : thumbnail}
											index={index}
											isMobile={isMobile}/>
			)}
		</ImagesContainer>
	</div>;
}

export default ProjectView;