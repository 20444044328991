import { useCallback, useEffect, useState } from "react";
import spinner from "../assets/spinner.svg";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
	imageStyle: {
		width: "100%",
		height: "100%",
		objectFit: "cover",
	}
})

export function ProgressiveImage({ src, thumbnail, alt, ...props }) {
	const [loaded, setLoaded] = useState(false);
	const [imageSrc, setImageSrc] = useState(thumbnail);
	const { imageStyle } = useStyles();

	useEffect(() => {
		setImageSrc(thumbnail);
	}, [thumbnail]);

	useEffect(() => {
		setLoaded(false);
	}, [src]);

	const onLoad = useCallback(() => {
		setImageSrc(src);
		setLoaded(true);
	}, [src]);

	const onError = useCallback(() => {
		setImageSrc(thumbnail);
	}, [thumbnail]);

	return (
		<>
			<img
				className={imageStyle}
				src={imageSrc}
				onLoad={onLoad}
				onError={onError}
				alt={alt}
				{...props}
			/>
			<img
				src={spinner}
				style={{
					position: "absolute",
					opacity: loaded ? 0 : 1,
					transition: "opacity 0.5s ease-out 0.3s",
					width: 200,
					animation: "spin 1s linear infinite",
				}}
				/>
		</>
	);
}