import { createUseStyles } from "react-jss";
import { useLocation } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiFacebook } from "@mdi/js";
import useWindowResize from "../windowDimensionHelper";
import { PhoneContact } from "./LinkWithIcon";

const useStyles = createUseStyles({
	main: {
		marginTop: "0.5rem",
		height: props => props.windowSizeCode === "s" ? "35px" : "80px",
		width: "100%",
		display: "flex",
		"& > *": {
			flex: "1 1 0",
		}
	}
})

const linkStyles = createUseStyles({
	main: {
		textDecoration: "none",
		color: props => props.color,
		"&:hover": {
			color: props => props.hoverColor,
		}
	}
})

function Quote({ windowSizeCode, ...props }) {
	return <div style={{
		margin: "0 auto",
		textAlign: "center",
		width: "max-content"
	}}
							{...props}>
		<h2 style={{
			width: "100%",
			marginBottom: "0.6rem",
			fontWeight: "normal",
			...(windowSizeCode === "s" ? {
				marginBlockStart: 0
			} : {})
		}}>
			"Architektura je způsob myšlení"
		</h2>
		<h4 style={{
			width: "100%",
			marginTop: 0,
			fontWeight: "normal",
		}}>
			Le Corbusier, 1936
		</h4>
	</div>;
}

function Footer() {
	const location = useLocation();
	const windowSizeCode = useWindowResize();
	const styles = useStyles({ windowSizeCode });

	const displayQuote = ["/", "/atelier"].includes(location.pathname);

	return (<>
		<div className={styles.main}>
			<div style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "flex-start",

			}}>
				<a
					className={linkStyles({ color: "#1976f2", hoverColor: "#13488f" }).main}
					href={"https://www.facebook.com/Ing-arch-%C5%A0%C3%A1rka-Seidlov%C3%A1-106910942255865"}
					rel={"noreferrer"}
					target={"_blank"}
				>
					<Icon path={mdiFacebook} size={1.5}/>
				</a>
			</div>
			{windowSizeCode !== "s" && <div>
				{displayQuote && <Quote windowSizeCode={windowSizeCode}/>}
			</div>}
			<div style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "flex-end",
			}}>
				<PhoneContact/>
			</div>
		</div>
		{windowSizeCode === "s" && <div>
			{displayQuote && <Quote windowSizeCode={windowSizeCode}/>}
		</div>}
	</>)
}

export default Footer;