import { useEffect, useRef, useState } from "react";
import { importAll } from "../helpers";
import useWindowResize from "../windowDimensionHelper";

/**
 * @type {string[]}
 */
const frontPageImageArray = {
	desktop: Object.values(importAll(require.context('../assets/frontPageImages/16-9/', true, /\.(png|jpe?g)$/))),
	mobile: Object.values(importAll(require.context('../assets/frontPageImages/9-16/', true, /\.(png|jpe?g)$/))),
};

function FrontPageImage(props) {
	const advanceImageTimeoutRef = useRef(null);

	const windowSizeStr = useWindowResize();
	const [sourceIndex, setSourceIndex] = useState(
		Math.floor(Math.random() *
			(windowSizeStr === "s" ? frontPageImageArray.mobile : frontPageImageArray.desktop).length
		)
	);

	function advanceImage() {
		let length = windowSizeStr === "s" ? frontPageImageArray.mobile.length : frontPageImageArray.desktop.length;
		setSourceIndex((sourceIndex + 1) % length);
	}

	function onImageLoaded() {
		advanceImageTimeoutRef.current = setTimeout(() => {
			advanceImage();
		}, 3000)
	}

	useEffect(() => {
		return () => clearTimeout(advanceImageTimeoutRef.current);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <img
		style={{
			width: "100%",
			maxWidth: 1500,
			aspectRatio: windowSizeStr === "s" ? "9/16" : "16/9",
			objectFit: "contain",
			...props.style
		}}
		src={windowSizeStr === "s" ? frontPageImageArray.mobile[sourceIndex] : frontPageImageArray.desktop[sourceIndex]}
		onLoad={onImageLoaded}
	/>
}

export default FrontPageImage;