import FrontPageImage from "../components/FrontPageImage";

function Home()
{
	return (
		<div style={{
			"display": "flex",
			"flexDirection": "column",
			"alignItems": "center",
			"justifyContent": "center",
			height: "100%",
		}}>
			<FrontPageImage/>
		</div>
	);
}

export default Home;